<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="allClasses"
      sort-by="calories"
      class="elevation-1"
      :loading="isGettingData"
      loading-text="يتم جلب البيانات ...الرجاء الانتظار"
    >
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-text-field
              v-model="searchkey"
              label="تحديد الاسم للبحث"
              prepend-icon="mdi-seach"
              clearable
            ></v-text-field>
          </td>

          <td>
            <v-btn class="ma-2" outlined color="indigo" @click="searchClasses">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title class="d-none d-sm-flex">الشعب</v-toolbar-title>
          <v-divider class="mx-4 d-none d-sm-flex" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            class="d-none d-sm-flex"
            @click="showAddNewClass = true"
            bottom
            color="blue darken-4"
            dark
            fab
            fixed
            left
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-dialog v-model="dialog" max-width="300px">
            <v-card>
              <v-card-title class="accent white--text darken-2"
                >تعديل البيانات</v-card-title
              >
              <v-container>
                <v-row>
                  <div>
                    <v-card flat>
                      <v-card-text>
                        <v-form ref="form">
                          <v-row class="mb-2">
                            <v-col cols="12">
                              <v-text-field
                                placeholder="الاسم"
                                v-model="editedItem.name"
                                required
                              />
                            </v-col>
                          </v-row>
                          <v-row class="mb-2">
                            <v-col cols="12">
                              <v-select
                                v-model="editedItem.stage"
                                :items="[
                                  'الاعدادية',
                                  'الثانوية_علمي',
                                  'الثانوية_أدبي',
                                ]"
                                placeholder="المرحلة"
                                required
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer />

                <v-btn
                  color="success"
                  class="mr-4"
                  @click="save"
                  :loading="isClick"
                  >حفظ</v-btn
                >
                <v-btn text @click="dialog = false">الغاء</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn rounded color="primary" dark @click="editClass(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn rounded class="mr-2" color="error" @click="deleteClass(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:no-data>لا يوجد أي شعبة محددة مسبقاً</template>
    </v-data-table>
    <AddClass :show.sync="showAddNewClass" @classAdded="refreshData()" />
  </div>
</template>

<script>
import { APIService } from "../../services/apiServices";
import AddClass from "../classes/AddClass.vue";
const apiService = new APIService();
export default {
  components: { AddClass },
  data: () => ({
    dialog: false,
    isClick: false,
    searchKey: "",
    showAddNewClass: false,
    headers: [
      {
        text: "الاسم",
        align: "start",
        value: "name",
      },
      {
        text: "المرحلة",
        align: "start",
        value: "stage",
      },
      { text: "العمليات", value: "action", sortable: false },
    ],
    allClasses: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      stage: "",
    },
    defaultItem: {
      name: "",
    },
  }),
  created() {
    this.getClasses();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "مادة جديدة" : "تعديل";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    getClasses() {
      this.isGettingData = true;
      const that = this;
      apiService
        .getClasses()
        .then((response) => {
          that.isGettingData = false;
          this.allClasses = response.classes;
        })
        .catch(() => {
          that.isGettingData = false;
        });
    },
    searchClasses() {
      this.isGettingData = true;
      const that = this;
      apiService
        .searchClasses(this.searchkey)
        .then((response) => {
          that.isGettingData = false;
          this.allClasses = response.classes;
        })
        .catch(() => {
          that.isGettingData = false;
        });
    },
    editClass(item) {
      this.editedIndex = this.allClasses.indexOf(item);
      //this.editedItem. = Object.assign({}, item);
      this.editedItem.id = item.id;
      this.editedItem.name = item.name;
      this.editedItem.stage = item.stage;
      this.dialog = true;
    },

    deleteClass(item) {
      var classObject = {
        Id: item.id,
      };
      if (confirm("هل تريد بالتأكيد حذف الشعبة")) {
        setTimeout(() => {
          apiService
            .deleteClass(classObject)
            .then((response) => {
              if (response == -1)
                alert(
                  "لا يمكن حذف هذه الشعبة. يجب حذف الشعبة من العام الدراسي أولا"
                );
              this.refreshData();
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            });
        }, 3000);
      }
    },
    refreshData() {
      this.getClasses();
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        this.isClick = true;
        setTimeout(() => {
          apiService
            .updateClass(this.editedItem)
            .then(() => {
              this.isClick = false;
              this.editedItem = Object.assign({}, this.defaultItem);
              this.close();
              this.refreshData();
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            });
        }, 3000);
      }
    },
  },
};
</script>
