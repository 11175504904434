<template>
  <v-dialog v-model="dialog" width="350px">
    <v-card>
      <v-card-title class="accent white--text darken-2"
        >شعبة جديدة</v-card-title
      >
      <v-container>
        <v-row justify="center">
          <div>
            <v-card flat width="300px" class="mx-auto">
              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <v-row class="mb-2">
                    <v-col cols="12">
                      <v-text-field
                        placeholder="الاسم"
                        v-model="newclass.name"
                        :rules="nameRules"
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mb-2">
                    <v-col cols="12">
                      <v-select
                        v-model="newclass.stage"
                        :items="[
                          'الاعدادية',
                          'الثانوية_علمي',
                          'الثانوية_أدبي',
                        ]"
                        placeholder="المرحلة"
                        required
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </div>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer />

        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="addClass"
          :loading="isClick"
          >حفظ</v-btn
        >
        <v-btn text @click="dialog = false">الغاء</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//new
import { APIService } from "../../services/apiServices";
const apiService = new APIService();
export default {
  // name: "AddSubject",
  props: {
    show: Boolean,
  },
  data: () => ({
    valid: true,
    addEnable: true,
    isClick: false,
    dialog: false,
    newclass: {
      id: 0,
      name: "",
      satge: "",
    },

    nameRules: [
      (v) => !!v || "يجب ادخال الاسم",
      (v) => (v && v.length <= 255) || "يجب أن يكون الاسم أقل من 255 حرف",
    ],
  }),
  methods: {
    addClass() {
      if (this.$refs.form.validate()) {
        this.isClick = true;
        this.addEnable = true;
        setTimeout(() => {
          apiService
            .addClass(this.newclass)
            .then((response) => {
              if (response != "") {
                this.newclass = {
                  id: 0,
                  name: "",
                  stage: 0,
                };

                this.dialog = false;
                this.$emit("classAdded");
              } else {
                this.isClick = false;
              }
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            })
            .finally(() => {
              this.isClick = false;
            });
        }, 3000);

        //this.dialog = false;
      }
    },
  },

  watch: {
    show(val) {
      this.dialog = val;
    },

    dialog(val) {
      this.$emit("update:show", val);
      const that = this;
      setTimeout(() => {
        that.$refs.form.resetValidation();
      }, 300);
    },
  },
};
</script>
